import { ReportActivityArchivedRequest, ReportActivityReadRequest, ReportActivitySummaryRequest } from "../entities/reports/ReportActivitySummary/ReportActivitySummaryRequest";
import { ReportActivityCountResponse, ReportActivitySummaryResponse } from "../entities/reports/ReportActivitySummary/ReportActivitySummaryResponse";
import { ActivityGetResponse } from "../pages/client/activity/entities/AcitivityGetResponse";
import { ActivityListRequest } from "../pages/client/activity/entities/AcitivityListRequest";
import { ActivityListResponse } from "../pages/client/activity/entities/AcitivityListResponse";
import { ActivityPendingResponse, WhatsAppListRequestBody, WhatsAppRequestBody, WhatsAppTypeSetResponse } from "../pages/client/activity/entities/AcitivityPendingResponse";
import { ActivitySetComment } from "../pages/client/activity/entities/ActivitySetComment";
import { EmailEdit } from "../pages/client/components/EmailCompose";
import { InvoiceListFilters } from "../pages/client/invoice/InvoiceTableProvider";
import { TypeListRequest } from "../pages/inbox/InboxTableProvider";

import { IoActivityListResponse } from "../pages/reports/entities/IoActivityListResponse";
import { TableHeader, TableHeaderWithFieldId } from "../pages/shared/Table";

import { restTimezone } from "../utils/ParseUtils";
import CompanyService, { DataType } from "./CompanyService";
import ExportService from "./ExportService";
import FilterService from "./FilterService";
import RequestService from "./RequestService";
import { CacheEntity, StorageService } from "./StorageService";
import { TranslationService } from "./TranslationService";

interface SetSMSParams {
    personid: number,
    id?: number,
    content: string,
    phone: string,
    activitytypeid?: number,
    tagids?: string,
    groupid?: string | number
}

interface PreviewEmailParams {
    email: EmailEdit,
    fileIds: string[],
    currentLanguage: string,
    personId: string,
    invoiceIds: number[],
    chkAllInv: boolean,
    invoiceFilters: InvoiceListFilters | undefined
}

export enum EmailInvoiceListType {
    All = 2,
    Pending = 1,
    Due = 0,
    Claimables = 4,
    DueClaimables = 5,
    Selected = 6,
    Other = 3,
}

export type SetEmailRequest = {
    item: {
        MessageID?: number;
        includelink?: boolean;
        files: {
            id: string;
            name: string;
        }[];
        draft: boolean;
        content: string;
        personid: number;
        from: string;
        to: string;
        subject: string;
        copyme: boolean;
        groupid?: string;
        groupName?: string;
        fromname?: string;
        html: boolean;
        activitytypeid?: number;
        language?: string;
        cc?: string;
        bcc?: string;
        includeinvoices: boolean;
        listmode: "0" | "1";
        mail_groupby?: number;
        mail_sortby?: string;
        reportexport?: number;
        mailframeid?: number;
        Tag_Message?: {
            TagID: number;
        }[];
        scheduleddate?: string;
        attach: boolean;
        includeattachment: boolean;
        IncludeAttachment: boolean;
        hasIO: boolean;
        replytoid: number | null;
        replacebody: "0" | "1";
        quickfilter?: EmailInvoiceListType;
        filter?: string | null;
        ids?: string;
        orderby0: number | undefined;
        orderby1: number | undefined;
        orderby2: number | undefined;
        orderbyorderdesc0: "on" | "off";
        orderbyorderdesc1: "on" | "off";
        orderbyorderdesc2: "on" | "off";
    },
}

class ActivityService {

    public static ActivityTypeFilterItems: { Label: string, Value: string }[] = [
        { Value: "4", Label: "WithManualActivities" },
        { Value: "0", Label: "WithEffectiveActivities" },
        { Value: "1", Label: "WithAutomaticActivities" },
        { Value: "5", Label: "AutomaticActivitiesOnly" },
        { Value: "2", Label: "WithUneffectiveActivities" },
        { Value: "3", Label: "NoActivities" },
    ];

    public static TypeFilterItems: { Label: string, Value: string }[] = [
        { Label: "FilterDefaultOptionLabel", "Value": "", },
        { Label: "Mails", Value: "1", },
        { Label: "Comments", Value: "2", },
        { Label: "Tasks", Value: "3", },
        { Label: "SMS", Value: "4", },
        { Label: "TypeWhatsApp", Value: "6", },
        { Label: "OutgoingEmails", Value: "-2", },
        { Label: "BouncedEmail", Value: "-1", },
    ];

    public static async setComment(request: ActivitySetComment): Promise<{ Response: string } | Error> {
        const result = await RequestService.post("/activity/setcomment", {
            ...request, additionaldefinitions: CompanyService.getAdditionalDefinitions(),
            activitytypes: CompanyService.getActivityTypes() ?? [],
        }) as { Response: string } | Error;
        if (!(result instanceof Error) && result.Response !== "Ok") {
            return new Error(result.Response);
        }
        return result;
    }
    public static async getList(request: ActivityListRequest | TypeListRequest) {
        const req: Promise<ActivityListResponse | Error> = RequestService.post("/activity/list", {
            ...request, additionaldefinitions: CompanyService.getAdditionalDefinitions(), users: CompanyService.getUsers()
        });
        req.then((result) => {
            if (result instanceof Error) {
                return;
            }
            result.list.forEach(x => {
                if (x.groupId && x.groupName && x.groupName.length > 0) {
                    StorageService.setCache(CacheEntity.GroupName, x.groupId, x.groupName);
                }
            })
        });
        return req;
    }
    public static async getSummary(request: ReportActivitySummaryRequest): Promise<ReportActivitySummaryResponse | Error> {
        return RequestService.post("/activity/summary", { ...request, additionaldefinitions: CompanyService.getAdditionalDefinitions(), users: CompanyService.getUsers(), currencies: CompanyService.getCurrencies(), iostatus: CompanyService.getIoStatus() });
    }
    public static async getPending(personid: string): Promise<ActivityPendingResponse | Error> {
        return RequestService.post("/activity/pending", { personid, additionaldefinitions: CompanyService.getAdditionalDefinitions() });
    }
    public static async delete(id: string): Promise<unknown | Error> {
        return RequestService.post("/activity/delete", { id });
    }
    public static async setSMS(params: SetSMSParams): Promise<{ Response: string, Id: number } | Error> {
        return RequestService.post("/activity/setsms", params);
    }
    public static async get(id: number): Promise<ActivityGetResponse | Error> {
        return RequestService.post("/activity/get", { id, additionaldefinitions: CompanyService.getAdditionalDefinitions() });
    }
    public static async setEmail(email: EmailEdit, personId: string, draft: boolean, loggedUserEmail: string, fileIds: string[], invoiceIds: number[]): Promise<{ Response: string, Id: number } | Error> {
        let filter = null;
        switch (email.invoiceListType) {
            case EmailInvoiceListType.All:
                filter = FilterService.GetExtraFiltersRequestString(email.invoiceFilters);
                break;
            case EmailInvoiceListType.Selected:
                // if (checkAll) {
                //     filter = FilterService.GetExtraFiltersRequestString(invoiceFilters?.extraFilters);
                // }    
                break;
        }

        let scheduledDateStr = undefined;
        if (email.programEmailDateTime) {
            const scheduledDate = new Date(email.programEmailDateTime);
            scheduledDateStr = `${scheduledDate.getFullYear()}-${scheduledDate.getMonth().toString().padStart(2, "0")}-${scheduledDate.getDay().toString().padStart(2, "0")} ${scheduledDate.getHours().toString().padStart(2, "0")}:${scheduledDate.getMinutes().toString().padStart(2, "0")}`;
        }
        const request: SetEmailRequest = {
            item: {
                MessageID: email.MessageID,
                files: fileIds.map(x => ({ id: x, name: "" })),
                draft,
                content: email.bodyHtml,
                personid: parseInt(personId),
                from: loggedUserEmail,
                to: email.to,
                subject: email.subject,
                copyme: email.sendCopy,
                groupid: email.groupId,
                fromname: CompanyService.getUsers().find(x => x.Id === CompanyService.getUserid())?.Value,
                html: true,
                activitytypeid: email.activityTypeId,
                language: email.invoiceLanguage,
                cc: email.toCC,
                bcc: email.toBCC,
                mail_groupby: email.invoiceGroupBy ? parseInt(email.invoiceGroupBy) : undefined,
                reportexport: email.invoiceReportExportId,
                mailframeid: email.emailFrameId,
                Tag_Message: email.tags?.split(",").filter(x => !!x).map(x => ({ TagID: parseInt(x) })),
                //replytoid
                scheduleddate: scheduledDateStr,
                attach: email.includeInvoiceList && email.invoiceListMode === "1",
                listmode: email.includeInvoiceList && email.invoiceListMode === "1" ? "0" : "1",
                includeinvoices: email.includeInvoiceList && email.invoiceListMode === "1",
                includeattachment: email.includeInvoiceFiles,
                IncludeAttachment: email.includeInvoiceFiles,
                hasIO: email.includeInvoiceList,
                replytoid: email.replytoid,
                replacebody: email.replacebody,
                quickfilter: email.includeInvoiceList && email.invoiceListType ? email.invoiceListType : undefined,
                filter,
                ids: email.invoiceListType === EmailInvoiceListType.Selected ? invoiceIds.join(",") : undefined,
                orderby0: 0,
                orderby1: 0,
                orderby2: 0,
                orderbyorderdesc0: "off",
                orderbyorderdesc1: "off",
                orderbyorderdesc2: "off",
            },
        };
        return RequestService.post("/activity/setemail", { ...request, additionaldefinitions: CompanyService.getAdditionalDefinitions() });
    }

    public static async export(reid?: number) {
        return RequestService.post("/activity/exportactivity", { reid });
    }

    public static async exportMessageIo(messageId: number, ioCount: number) {
        return ExportService.requestExport("/activity/exportmessageiolist", { id: messageId, additionaldefinitions: CompanyService.getAdditionalDefinitions() }, ioCount);
    }

    public static async exportIoActivityList(reid: number) {
        return ExportService.requestExport("/activity/exportioactivitylist", { reid }, 100, 'ioactivitylist');
    }

    public static async setEmailNew(request: SetEmailRequest): Promise<{ Response: string, Id: number } | Error> {
        return RequestService.post("/activity/setemail", { ...request, additionaldefinitions: CompanyService.getAdditionalDefinitions() });
    }

    public static async getEmailContent(request: { id: string, noheader: "0" | "1" }): Promise<{ Response: string, content: string } | Error> {
        return RequestService.post("/activity/getemailcontent", request);
    }
    public static async editEmail(messageId: number, sent: string, activityTypeId?: string, tags?: string, groupId?: string): Promise<{ Response: string, id: number } | Error> {
        const request = {
            item: {
                messageid: messageId,
                sent: restTimezone(new Date(sent)).toJSON(),
                activitytypeid: activityTypeId ? parseInt(activityTypeId) : undefined,
                Tag_Message: tags?.split(", ").map(x => ({ TagID: parseInt(x) })).filter(x => x.TagID),
                groupid: groupId,
            },
        };
        return RequestService.post("/activity/setemailsent", request);
    }
    // public static async getEmailTemplate(id: string): Promise<{ Response: string, Content: string, Subject: string, ActivityTypeID: string, Attachments: { filename: string, id: number }[] } | Error> {
    //     return RequestService.post("/activity/getemailtemplate", { id });
    // }

    public static async previewEmailContent(request: SetEmailRequest): Promise<{ Response: string, preview: string } | Error> {
        return RequestService.post("/activity/previewemail", { ...request, additionaldefinitions: CompanyService.getAdditionalDefinitions() });
    }
    public static async previewEmail({ email, fileIds, currentLanguage, personId, invoiceIds, chkAllInv, invoiceFilters }: PreviewEmailParams): Promise<{ Response: string, preview: string } | Error> {
        const request = {
            response: "",
            id: undefined,
            item: {
                files: fileIds.map(x => ({ id: x })),
                draft: true,
                content: email.bodyHtml,
                personid: parseInt(personId),
                from: CompanyService.getUserEmail(),
                Fromname: CompanyService.getUsers().find(x => x.Id === CompanyService.getUserid())?.Value,
                to: email.to,
                type: email.activityTypeId,
                subject: email.subject,
                copyme: email.sendCopy,
                groupid: email.groupId,
                html: true,
                activitytypeid: email.activityTypeId,
                language: currentLanguage,
                cc: email.toCC,
                bcc: email.toBCC,
                mail_groupby: email.invoiceGroupBy,
                reportexportid: email.invoiceReportExportId,
                mailframeid: email.emailFrameId,
                //replytoid
                attach: email.includeInvoiceFiles,
                includeattachment: email.includeInvoiceFiles,
                IncludeAttachment: email.includeInvoiceFiles,
                Tag_Message: email.tags?.split(",").filter(x => !!x).map(x => ({ TagID: parseInt(x) })),
            },
            ids: chkAllInv ? undefined : invoiceIds.join(","),
            filter: FilterService.GetExtraFiltersRequestString(chkAllInv ? invoiceFilters?.extraFilters : email.invoiceFilters),
            quickfilter: email.invoiceListType,
        };
        return RequestService.post("/activity/previewemail", { request, additionaldefinitions: CompanyService.getAdditionalDefinitions() });
    }

    public static getActivityHeadersStart() {
        const translate = TranslationService.translate;
        const fixedFields: TableHeaderWithFieldId[] = [
            {
                ...new TableHeader(
                    "Date",
                    translate.Date,
                    false,
                    false,
                    "w-120px"
                ),
                fieldId: "date",
                type: DataType.Date,
            },
            {
                ...new TableHeader(
                    "User",
                    translate.User,
                    false,
                    false,
                    "w-200px"
                ),
                fieldId: "Username",
                type: DataType.Text,
            }
        ];

        return fixedFields;
    }

    public static getActivityHeadersEnd() {
        const translate = TranslationService.translate;
        const fixedFields: TableHeaderWithFieldId[] = [
            {
                ...new TableHeader(
                    "groupName",
                    CompanyService.getGroupName(),
                    false,
                    false,
                    "w-120px"
                ),
                fieldId: "groupName",
                type: DataType.Text,
            },
            {
                ...new TableHeader(
                    "ActivityTypeID",
                    translate.ActivityType,
                    false,
                    false,
                    "w-120px"
                ),
                fieldId: "ActivityTypeID",
                type: DataType.Text,
            },
            {
                ...new TableHeader(
                    "typeName",
                    translate.Type,
                    false,
                    false,
                    "w-150px"
                ),
                fieldId: "typeName",
                type: DataType.Text,
            },

            {
                ...new TableHeader(
                    "Content",
                    translate.Content,
                    false,
                    false,
                    "w-400px"
                ),
                fieldId: "Content",
                type: DataType.Text,
            },
            {
                ...new TableHeader(
                    "tagList",
                    translate.Tags,
                    false,
                    false,
                    "w-150px"
                ),
                fieldId: "tagList",
                type: DataType.Text,
            },
            {
                ...new TableHeader(
                    "hasAttachments",
                    translate.Attachments,
                    false,
                    false,
                    "w-80px"
                ),
                fieldId: "hasAttachments",
                type: DataType.Text,
            }
            ,
            {
                ...new TableHeader(
                    "date2",
                    translate.Created,
                    false,
                    false,
                    "w-120px"
                ),
                fieldId: "date2",
                type: DataType.Date,
            }
        ];

        //const fields = [...fixedFields, ...additionalFields];
        // ClientService.clientHeadersIds.forEach((fieldId, index) => {
        //     const oldIndex = fields.findIndex(x => x.fieldId === fieldId);
        //     const tmp = fields[oldIndex];
        //     fields[oldIndex] = fields[index];
        //     fields[index] = tmp;
        // });
        return fixedFields;
    }

    public static async getIoActivityList(request: ReportActivitySummaryRequest): Promise<IoActivityListResponse | Error> {
        return RequestService.post("/activity/ioactivitylist", {
            ...request,
            additionaldefinitions: CompanyService.getAdditionalDefinitions(),
            currencies: CompanyService.getCurrencies(),
            users: CompanyService.getUsers(),
            iostatus: CompanyService.getIoStatus()
        });
    }

    public static async setWhatsApp(whatsAppRequest: WhatsAppRequestBody): Promise<WhatsAppTypeSetResponse | Error> {
        return RequestService.post("/activity/setwhatsapp", {
            ...whatsAppRequest,
            additionaldefinitions: CompanyService.getAdditionalDefinitions()
        });
    }
    public static async getListWhatsappMessages(whatsAppRequest: WhatsAppListRequestBody): Promise<any | Error> {
        return RequestService.post("/activity/listwhatsappmessages", {
            ...whatsAppRequest,
            additionaldefinitions: CompanyService.getAdditionalDefinitions()
        });
    }
    public static async getUnreadCount(): Promise<ReportActivityCountResponse | Error> {
        return RequestService.post("/activity/getunreadcountinbox", {});
    }
    public static async setRead(request: ReportActivityReadRequest): Promise<any | Error> {
        return RequestService.post("/activity/setread", { ...request }); // 0 unread 1 read
    }
    public static async setArchived(request: ReportActivityArchivedRequest): Promise<any | Error> {
        return RequestService.post("/activity/setarchive", { ...request }); // 0 unread 1 read
    }
}

export default ActivityService;