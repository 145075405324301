import { useMemo } from "react";
import { PortalPaginator } from "./PortalPaginador";
import { TableHeader } from "../../shared/Table";
import TooltipComponent from "../../shared/TooltipComponent";
import { formatInteger } from "../../../utils/FormatUtils";
import { TranslationService } from "../../../services/TranslationService";
import { DataType } from "../../../services/CompanyService";
import { ButtonTooltipIcon } from "../../shared/components/ButtonTooltipIcon";
import ExportPortalService from "../../../services/ExportPortalService";
import Dropdown from "../../shared/components/Dropdown";

export const PortalInvocesTable = ({ response, setRequest, request, setOtherFilters }: any) => {
    const filterItems = useMemo(() => {
        let filterItems = [
            { text: 'Facturas Vencidas', key: 0, value: 0 },
            { text: 'Todas las facturas pendientes', key: 1, value: 1 },
            { text: 'Otros Filtros', key: 3, value: 3 },
        ];

        if (response?.customerportalshowhistory) {
            filterItems.push({ text: 'Todas las facturas', key: 2, value: 2 });
        }
        return filterItems;
    }, [response?.customerportalshowhistory]);

    const tableHead: TableHeader[] = useMemo(() =>
        response?.data &&
        response?.data?.headers &&
        response?.data?.headers?.map((h: any) =>
            new TableHeader(h.columnId, h.columnTitle, h.dataType === DataType.Number, false) || []
        ), [response?.data])


    const returnValue = (h: TableHeader, row: any) => {
        let keyRow = row.find((r: any) => r.Key === h.key);
        let dataRow = <td className={`${h.className}`}>{keyRow?.Value}</td>;
        if (h.numeric) {
            dataRow = <td className={`text-end ${h.className}`}>${formatInteger(keyRow?.Value ?? 0)}</td>;
        }
        if (h.tooltip.length) {
            dataRow = <TooltipComponent title={h.tooltip}>{dataRow}</TooltipComponent>
        }
        return dataRow;
    }

    const exportInvoiceList = async () => {
        await ExportPortalService.requestExport("/customer/exportiolist", {}, 1, 'customer_list.xlsx');
    };

    const selectGroupInvoice = (quickFilter: number) => {
        setOtherFilters(quickFilter === 3);
        setRequest({ ...request, quickFilter });
    }

    return (
        <>
            <div className="d-flex col-md-6 align-items-center justify-content-md-start justify-content-between my-2 ps-md-4">
                <div className="col-md-1 col-auto align-items-center">
                    <ButtonTooltipIcon
                        title={TranslationService.translate.export}
                        icon="fa-light fa-arrow-down-to-line text-granite-gray ms-1"
                        onClick={exportInvoiceList}
                        isLink={false}
                    />
                </div>

                <div className="col-md-4 col-9">
                    <Dropdown items={filterItems} onChange={selectGroupInvoice} defaultValue={request?.quickFilter || undefined} />
                </div>

                <div className="col-md-1 col-auto align-items-center">
                    <ButtonTooltipIcon
                        title={TranslationService.translate.Filters}
                        icon="fal fa-filter"
                        isLink={false}
                        onClick={() => undefined} />
                </div>
            </div>
            <div className="pt-0">
                {/* <AdvancedFilters page={FilterCollection.PortalFixed} /> */}
            </div>
            <div className="tableContainer overflow-x-hidden sticky-top">
                <div className="table-responsive p-md-4 p-0">
                    <table className="table">
                        <thead>
                            <tr>
                                {tableHead?.map((h) => <th>{h.label()}</th>)}
                            </tr>
                        </thead>
                        <tbody className="overflow-x">
                            {response?.data?.rowsToShow?.map((row: any, idx: number) => (
                                <tr key={idx}>
                                    {tableHead?.map(h => returnValue(h, row))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className={"d-flex gap-2 fw-bold"} style={{ backgroundColor: "#ddedf9" }}>
                        <div className="col-12 col-md-3 d-flex gap-2 py-2 px-3" style={{ backgroundColor: "#ddedf9" }}>
                            <div className="col-4">{TranslationService.translate.Total}</div>
                            <div>{formatInteger(response.data.totalAmount ?? 0)}</div>
                        </div>
                        <div className="col-12 col-md-3 d-flex gap-2 py-2 px-3" style={{ backgroundColor: "#ddedf9" }}>
                            <div className="col-4">{TranslationService.translate.PendingAmount}</div>
                            <div>{formatInteger(response.data.totalPending ?? 0)}</div>
                        </div>
                    </div>
                </div>

                <PortalPaginator
                    totalRecords={response.data.count}
                    recordsPerPage={response.data.count}
                    currentPage={1}
                    onPageChange={() => { }}
                />
            </div>
        </>
    )
}
