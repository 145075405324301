import React, { useCallback, useContext, useEffect, useState } from "react";
import { TranslationService } from "../../../services/TranslationService";
import ButtonPrimary from "../../account/components/ButtonPrimary";
import * as yup from "yup";
import Dropdown from "../../shared/components/Dropdown";
import { CheckBoxEditor } from "../../shared/components/Editors";
import ButtonSecondary from "../../account/components/ButtonSecondary";
import { TitleConfig } from "./components/TitleConfig";
import FileService from "../../../services/FileService";
import ToastContext from "../../shared/bootstrap/Toast";

type BackupConfigurationType = {
    backupType?: number;
    periodOption?: number;
    specificDays?: number;
    including?: number;
    lastBackup?: string;
    // weeks
    weekMonday?: boolean;
    weekTuesday?: boolean;
    weekWednesday?: boolean;
    weekThursday?: boolean;
    weekFriday?: boolean;
    weekSaturday?: boolean;
    weekSunday?: boolean;
}

export const BackupConfiguration = ({
    backupType = 0,
    periodOption = 1,
    specificDays = 22, 
    including = 60,
    lastBackup = "18/07/2023",
    // weeks
    weekMonday = false,
    weekTuesday = false,
    weekWednesday = false,
    weekThursday = false,
    weekFriday = false,
    weekSaturday = false,
    weekSunday = false,
}: BackupConfigurationType) => {
    const { translate } = TranslationService;
    const { showToast } = useContext(ToastContext);

    const [error, setError] = useState("");
    const [saveData, setSaveData] = useState(false);
    const [backupRequested, setBackupRequested] = useState(false);
    const [formData, setFormData] = useState({
        backupType,
        periodOption,
        specificDays,
        including,
        weekMonday,
        weekTuesday,
        weekWednesday,
        weekThursday,
        weekFriday,
        weekSaturday,
        weekSunday,
    });

    const OPTIONS_BACKUP = [
        { value: 0, text: TranslationService.getTranslation("PartialBackup") },
        { value: 1, text: TranslationService.getTranslation("OnlyPendingAnd60DaysOfInteractions") },
        { value: 2, text: TranslationService.getTranslation("FullBackup") },
    ];

    const OPTIONS_PERIOD = [
        { value: 0, text: TranslationService.getTranslation("Weekly") },
        { value: 1, text: TranslationService.getTranslation("Monthly") },
    ];

    const WEEKS = [
        { week: weekMonday, weekText: "WeekMonday", weekProp: "weekMonday" },
        { week: weekTuesday, weekText: "WeekTuesday", weekProp: "weekTuesday" },
        { week: weekWednesday, weekText: "WeekWednesday", weekProp: "weekWednesday" },
        { week: weekThursday, weekText: "WeekThursday", weekProp: "weekThursday" },
        { week: weekFriday, weekText: "WeekFriday", weekProp: "weekFriday" },
        { week: weekSaturday, weekText: "WeekSaturday", weekProp: "weekSaturday" },
        { week: weekSunday, weekText: "WeekSunday", weekProp: "weekSunday" },
    ] as const;


    const formDataSchema = yup.object({});

    const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleSelect = <T extends keyof typeof formData,>(valueUpdated: (typeof formData)[T], name: T) => {
        setFormData((prevState) => ({ ...prevState, [name]: valueUpdated }));
    };

    const handleRequestBackup = () => {
        setBackupRequested(true);
    };

    const handleDownloadLastBackup = () => {
        FileService.downloadLastBackup()
            .then((result) => {
                if (result instanceof Error) {
                    showToast(translate.ErrorProcessingRequest, undefined, "danger");
                    return result;
                }
            });
    };

    const saveValues = useCallback(() => {
        setSaveData(false);
        // // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        formDataSchema.validate(formData)
            .then(() => {
                setError("");
                setSaveData(true);
            })
            .catch((err) => setError(err.errors[0]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    return (
        <div className='col-md-10'>
            <div className='col-md-10 mx-3'>
                <TitleConfig title={translate.Backup} />
                {backupRequested
                    ? <TitleConfig title='Tu solicitud fue recibida. Te enviaremos un email cuando la copia de respaldo esté generada.' />
                    :
                    <>
                        <div className='col-sm-11 d-flex justify-content-between align-items-center mb-4 gap-2'>
                            <div className='col-sm-5'><Dropdown items={OPTIONS_BACKUP} onChange={(value) => handleSelect(value, "backupType")} defaultValue={backupType} /></div>
                            {backupType === 0 &&
                                <div className="col d-flex align-items-center">
                                    <div className="mx-2 text-end">{translate.Including}</div>
                                    <input name='including' defaultValue={formData.including} onChange={handleInputChange} type="number" className="form-control form-control-sm" aria-describedby="basic-addon1" />
                                    <div className=" mx-2 text-end">{translate.Days}</div>
                                </div>}
                            <div className='col-sm-4'>
                                <ButtonPrimary onClick={handleRequestBackup}>{translate.RequestBackup}</ButtonPrimary>
                            </div>
                        </div>
                    </>}

                <TitleConfig title={translate.ScheduleBackupCopy} />
                <div className='col-md-11 d-flex justify-content-between align-items-center mb-4 gap-2'>
                    <div className='col-md-5'><Dropdown items={OPTIONS_BACKUP} onChange={(value) => handleSelect(value, "backupType")} defaultValue={backupType} /></div>
                    <div className='col-md-4'><Dropdown items={OPTIONS_PERIOD} onChange={(value) => handleSelect(value, "periodOption")} defaultValue={periodOption} /></div>
                    <div className="col-md-3  d-flex  align-items-center">
                        <div className=" mx-2 text-end">{translate.SpecificDays}</div>
                        <input name='specificDays' className="col form-control form-control-md" defaultValue={formData.specificDays} onChange={handleInputChange} type="number" aria-describedby="basic-addon1" />
                    </div>
                </div>
            </div>

            <div className='d-flex col justify-between mb-3'>
                {WEEKS.map(({ weekProp, weekText }) =>
                    <div className='col' key={weekProp}>
                        <CheckBoxEditor
                            onChange={value => handleSelect(value, weekProp)}
                            message={TranslationService.getTranslation(weekText)}
                        />
                    </div>
                )}
            </div>

            <div className="align-items-center input-group mb-3">
                <ButtonSecondary onClick={handleDownloadLastBackup}>{translate.DownloadLastBackup}</ButtonSecondary>
                <div className=" mx-2 text-end">({lastBackup})</div>
            </div>


            <div>
                <div className='d-flex justify-content-between align-items-center'>
                    <div id="errorMsj" className="incorrect" style={{ color: "red", fontSize: "14px", marginTop: "10px" }}>{error}</div>
                    {saveData && !error && <ButtonPrimary onClick={saveValues}>{translate.Save}</ButtonPrimary>}
                </div>
            </div>
        </div>
    );
};
