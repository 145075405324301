import Translations from "../assets/translations/translations.json";
import { CompanyAuth } from "../entities/login/CompanyAuth";
import { capitalize, uncapitalize } from "../utils/FormatUtils";
import { getCache } from "../utils/PersistenceUtils";
import { TupleToUnion } from "../utils/TypeUtils";

const customerFieldName = getCache<CompanyAuth>("company_auth")?.customerFieldName || "";
const customersFieldName = getCache<CompanyAuth>("company_auth")?.customersFieldName || "";
const oldTranslations = JSON.stringify(Translations)
    .replaceAll("{Cliente}", capitalize(customerFieldName))
    .replaceAll("{Clientes}", capitalize(customersFieldName))
    .replaceAll("{cliente}", uncapitalize(customerFieldName))
    .replaceAll("{clientes}", uncapitalize(customersFieldName));
const newTranslations = JSON.parse(oldTranslations);

export type Languages = ["es", "en", "pt"];
const availableLanguages: Languages = ["es", "en", "pt"];
export type Language = TupleToUnion<typeof availableLanguages>;
export type TranslationsKey = keyof typeof newTranslations;
export type LangIndex<T> = { [t in Language]: T };
const translatedLanguages: { [t in Language]: { [key in TranslationsKey]: string } } = { es: {}, en: {}, pt: {} } as { [t in Language]: { [key in TranslationsKey]: string } };
availableLanguages.forEach(lang => {
    Object.keys(newTranslations).forEach(key => {
        const entry = (newTranslations as { [key: string]: { [lang: string]: string | undefined } })[key][lang];
        if (entry !== undefined) {
            translatedLanguages[lang][key as TranslationsKey] = entry;
            return;
        }
        if (process.env.NODE_ENV === "production") {
            translatedLanguages[lang][key as TranslationsKey] = "";
        } else {
            // console.error('Missing translation! Not suitable for production: ' + key);
            translatedLanguages[lang][key as TranslationsKey] = `Missing '${key}' in '${lang}'`;
        }
    });
});

export class TranslationService {
    public static currentLanguage: Language = "es";
    public static translate: Readonly<typeof translatedLanguages[Language] & { [key: string]: string }> = translatedLanguages["es"];
    public static getTranslation(key: string) {
        return TranslationService.translate[key as "Action"];
    }
    public static setCurrentLanguage(lang: Language) {
        if (lang === TranslationService.currentLanguage) {
            return;
        }
        TranslationService.currentLanguage = lang;
        TranslationService.translate = translatedLanguages[lang];
    }
    public static getCurrentLanguage(): Language {
        return TranslationService.currentLanguage;
    }
    public static setCurrentLanguageReplace(lang: Language, fieldName: string, fieldsName: string) {
        const oldTranslations = JSON.stringify(translatedLanguages[lang || 'es'])
            .replaceAll("{Cliente}", fieldName)
            .replaceAll("{Clientes}", fieldsName);

        const newTranslations = JSON.parse(oldTranslations);
        TranslationService.translate = newTranslations;
    }
}