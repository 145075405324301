import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TranslationService } from "../../services/TranslationService";
import PortalClientService, { PortalClientDataRequest, PortalClientResponse } from "../../services/PortalClientService";
import PortalClientTableProvider, { PortalClientTableContextValues } from "./providers/PortalClientTableProvider";
import TableContext from "../task/TableContext";
import Loading from "../shared/components/Loading";
import { PortalHeader } from "./components/PortalHeader";
import { PortalInvocesTable } from "./components/PortalInvocesTable";
import './styles.css';

export const PortalClientContainer = () => {
  const [dataClient, setDataClient] = useState<PortalClientResponse>();
  const history = useHistory()
  const tokenId = new URLSearchParams(history.location.search).get("t") ?? "";
  const { currentLanguage } = TranslationService;

  useEffect(() => {
    const loadData = async (token: string) => {
      const result = await PortalClientService.getClientData(token);
      if (result instanceof Error) {
        console.error({ result })
        return;
      } else if (result?.data) {
        setDataClient(result.data);
      }
    };

    if (tokenId) {
      loadData(tokenId);
    } else {
      history.push(`/${currentLanguage}/`)
    }
  }, [currentLanguage, history, tokenId])

  return (
    <PortalClientTableProvider token={tokenId} prevRequest={new PortalClientDataRequest()}>
      <PortalClient dataClient={dataClient} />
    </PortalClientTableProvider>
  )
}

const PortalClient = ({ dataClient }: { dataClient?: PortalClientResponse }) => {
  const [otherFilters, setOtherFilters] = useState(false)
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const { response, setRequest, request, loading } = useContext(TableContext) as PortalClientTableContextValues;

  const togglePanel = () => setIsPanelOpen(!isPanelOpen);

  return (
    <>
      <PortalHeader
        logo={dataClient?.urlLogo}
        personName={dataClient?.personName || "Cliente"}
      />

      <div
        className={"d-flex flex-column flex-grow-1 bg-white"}
        style={{ backgroundColor: "#86c8ea", minHeight: "calc(100vh - 179px)" }}
      >
        <div className="bg-white">
          {/* <PortalPanel {...{ isPanelOpen, togglePanel }} /> */}

          <ul className="sticky-to-top bg-white nav nav-tabs px-4 pt-2" id="pills-tab" role="tablist">
            <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">
              Facturas
            </button>
            <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">
              Mis datos
            </button>
            <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">
              Formas de pago
            </button>
          </ul>

          <div className="tab-content p-0" id="pills-tabContent">
            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
              {
                (!dataClient || loading)
                  ?
                  <div
                    className="d-flex justify-content-center align-items-center mh-100 p-0"
                    style={{ backgroundColor: "#f2f1f6", minHeight: "calc(100vh - 179px)" }}
                  >
                    <Loading />
                  </div>
                  :
                  <PortalInvocesTable  {...{ response, setRequest, request, togglePanel, otherFilters, setOtherFilters }} />
              }
            </div>
            <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
              <div className="container mt-5">
                <div className="row">

                  <div className="col-md-8">
                    <h2 className="h4">Información del Cliente</h2>
                    {dataClient?.personName &&
                      <div className="row mb-2">
                        <div className="col-4"><strong>Nombre:</strong></div>
                        <div className="col-8">{dataClient.personName}</div>
                      </div>}

                    {dataClient?.clientInfo &&
                      <>
                        {
                          Object.keys(dataClient.clientInfo)
                            .filter(el => el !== 'Hipervínculo')
                            .map((el) =>
                              <div className="row mb-2">
                                <div className="col-4"><strong>{el}:</strong></div>
                                <div className="col-8">{dataClient.clientInfo[el]}</div>
                              </div>)
                        }
                        {
                          dataClient.clientInfo?.Hipervínculo &&
                          <div className="row mb-2">
                            <div className="col-4"><strong>Hipervínculo:</strong></div>
                            <div className="col-8">
                              <a href={dataClient?.clientInfo.Hipervínculo} target="_blank" rel="noopener noreferrer">Ver Cliente</a>
                            </div>
                          </div>
                        }
                      </>
                    }

                  </div>
                  <div className="col-md-4 text-center my-2">
                    <img
                      alt="Logo"
                      className="img-fluid"
                      src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTW593NZ2Afk0kUQw3mbFZLulYAHKgyGgRI5g&s'}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
              <div className="container mt-5">
                <div className="row">
                  <div className="col-md-8">
                    <h2 className="h4">Método de pago</h2>
                    <div className="row mb-2">
                      {
                        dataClient?.paymentMethod ?
                          <div className="col-12" dangerouslySetInnerHTML={{ __html: dataClient.paymentMethod }} /> :
                          <div className="col-12">No hay datos para mostrar</div>
                      }
                    </div>
                  </div>
                  <div className="col-md-4 text-center my-2">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


export default PortalClient;
