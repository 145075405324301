import { ErrorProvider } from "../shared/ErrorContext";
import { PortalClientContainer } from "../account/PortalClient";
import StaticFooter from "../shared/Footer";

const PortalSite = (props) => {
    return (
        <div>
            <ErrorProvider>
                <PortalClientContainer />
                <StaticFooter lang={props.lang} />
            </ErrorProvider>
        </div>
    );
};

export default PortalSite;
