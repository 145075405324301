import { useContext, useMemo, useRef, useState } from "react";
import Dropdown from "../../shared/components/Dropdown";
import {
    FileApi,
    DatePickerEditor,
    AutocompleteSelect,
    HasValidationEditor,
    HasValidationEditorProps,
    TextAreaBigEditor,
    MultiselectEditor
} from "../../shared/components/Editors";
import { TranslationService } from "../../../services/TranslationService";
import { TaskEdit } from "../entities/TaskEdit";
import { TaskStatus, getStatusTranslateKey } from "../entities/TaskStatus";
import ToastContext from "../../shared/bootstrap/Toast";
import GroupService from "../../../services/GroupService";
import ClientService from "../../../services/ClientService";
import TaskService from "../../../services/TaskSerivce";
import FloatingPanelService from "../../shared/FloatingPanel";
import TagService from "../../../services/TagService";
import { FloatingPanelFooter } from "../../shared/components/FloatingPanelFooter";
import CompanyService, { DataType } from "../../../services/CompanyService";
import { RequiredManager, ValidationMessage } from "../../shared/RequieredManager";
import FileService from "../../../services/FileService";
import { MultiFileUpload } from "../../shared/components/MultiFileUpload";
import SwitchButton from "../../shared/components/SwitchButton";
import { ButtonTooltipIcon } from "../../shared/components/ButtonTooltipIcon";

type NewTaskProp = {
    onNewTask?: () => void
} & (
        { personId: string, personName: string } |
        { personId?: undefined, personName?: undefined }
    );

const NewTaskForm = ({ onNewTask = undefined, personId = undefined, personName = undefined }: NewTaskProp) => {
    const loggedUserId = CompanyService.getUserid() ?? "";
    const { translate } = TranslationService;
    const { showToast } = useContext(ToastContext);
    const requiredManager = useRef(new RequiredManager()).current;

    const [taskEdit, setTaskEdit] = useState(new TaskEdit(loggedUserId, personId));
    const [isSaving, setIsSaving] = useState(false);
    const [clientMandatoryMessage, setClientMandatoryMessage] = useState(false);
    const requiredManger = new RequiredManager();
    const validationFunctionArray: (() => boolean)[] = [];
    const getUserOptions = CompanyService.getUsers()?.map(x => ({ value: x.Id, text: x.Value ?? "", key: x.Id })) ?? [];
    const getActivityTypeOptions = CompanyService.getActivityTypes().filter(x => x.Task).map(x => ({ value: x.ActivityTypeID, text: x.ActivityTypeName ?? "" })) ?? [];
    const getStatusOptions = [TaskStatus.Pending, TaskStatus.Fulfilled].map(x => ({ value: x as number, text: TranslationService.getTranslation(getStatusTranslateKey(x)) }));
    const getReminderHourOptions = [...Array(24).keys()].map(x => ({ value: x.toString(), text: (x + ":00").padStart(5, "0") }));

    const taskFieldChange = (propName: keyof TaskEdit) => (newValue: string | number | boolean | undefined) => {
        setTaskEdit(x => ({ ...x, [propName]: newValue }));
    };

    const taskFileChange = (fileIdList: FileApi[]) => {
        const files = fileIdList.map(x => x.id);
        setTaskEdit(x => ({ ...x, files }));
    };

    const clientSelectOptions = async (inputValue: string) => {
        let list: Array<{ value: string, label: string }> = [];
        const minInputLength = 2;
        if (inputValue.length < minInputLength) { return list; }
        const result = await ClientService.search(inputValue);
        if (!(result instanceof Error)) {
            list = result.list.map(x => ({ value: x.id.toString(), label: x.name }));
        }
        return list;
    };

    const showDoneDate = taskEdit.statusId !== undefined && +taskEdit.statusId === TaskStatus.Fulfilled;

    const canSaveTask = () => {
        setClientMandatoryMessage(!taskEdit.clientId);
        return requiredManger.validate() && validationFunctionArray.filter(x => !x()).length === 0 && taskEdit.clientId;
    };

    const saveTask = async () => {
        if (isSaving || !canSaveTask()) {
            return;
        }
        setIsSaving(true);
        if (taskEdit.tagsCsv) {
            const tagResult = await TagService.createMissingTags(taskEdit.tagsCsv);
            if (tagResult instanceof Error) {
                showToast(translate.ErrorProcessingRequest, undefined, "danger");
                setIsSaving(false);
                return;
            }
            taskEdit.tagsCsv = tagResult;
        }

        const result = await TaskService.setTask(taskEdit, loggedUserId);
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
            setIsSaving(false);
        }
        else {
            showToast(translate.TaskSaved, undefined, "success");
            setTaskEdit(new TaskEdit(loggedUserId?.toString()));
            setIsSaving(false);
            onNewTask && onNewTask();
            FloatingPanelService.hidePanel();
        }
    };

    const fields: {
        title: string;
        onChange: (value: string) => void;
        type: DataType;
        items: { value: string; text: string; }[],
        defaultValue?: (current?: any) => any;
        className: string;
        secondaryVariant: boolean;
        placeholder?: string;
        extraStyle?: React.CSSProperties
    }[] = useMemo(() => [
        {
            title: "",
            onChange: requiredManager.makeRequiredWithId((value: string) => taskFieldChange("description")(value), 'description'),
            type: DataType.CommentsLink,
            items: [],
            defaultValue: (current) => current?.item.Content,
            className: "",
            secondaryVariant: false,
            placeholder: translate.Description + "...",
        },
    ], [requiredManager, translate.Description]);

    return (
        <>
            <div className="tabBody">
                <div className="row align-items-center mb-3 g-0">
                    <div className="col-2 pe-2">
                        <label className="form-label mb-0">{translate.Client}</label>
                    </div>
                    <div className="col-10">
                        {personId === undefined && <AutocompleteSelect loadOptions={clientSelectOptions} onChange={clientId => { taskFieldChange("clientId")(clientId?.value); setClientMandatoryMessage(!clientId); }} />}
                        {personId !== undefined && <p className=''>{personName}</p>}
                        {clientMandatoryMessage && <p className='text-danger mb-0'>{translate.ClientMandatoryMessage}</p>}
                    </div>
                </div>
                <div className="row align-items-center mb-2 g-0">
                    <div className="col-2">
                        <label className="form-label mb-0">{translate.Responsible}</label>
                    </div>
                    <div className="col-4 pe-2 ">
                        <Dropdown optionLabel={translate.NotAssigned} onChange={taskFieldChange("userResponsibleId")} items={getUserOptions} defaultValue={CompanyService.getUserid()} />
                    </div>
                    <div className="col-2 ps-2">
                        <label className="form-label mb-0">{translate.ExpirationDay}</label>
                    </div>
                    <div className="col-4">
                        <HasValidationEditor {...new HasValidationEditorProps(
                            DatePickerEditor,
                            taskFieldChange("dueDate"),
                            <p className='text-danger mb-0'>{translate.RequiredField}</p>,
                            undefined,
                            taskEdit.dueDate,
                            validationFunctionArray
                        )} />
                    </div>
                </div>
                <div className="row align-items-center mb-2 g-0">
                    {Boolean(CompanyService.getTags()?.length) &&
                        <>
                            <div className="col-2">
                                <label className="form-label mb-0">{translate.Status}</label>
                            </div>
                            <div className="col-4 pe-2">
                                <Dropdown onChange={taskFieldChange("statusId")} items={getStatusOptions}></Dropdown>
                                <ValidationMessage onChange={CompanyService.getSetting("mandatorytasktype") ? requiredManger.getValidation("activitytype") : () => undefined} defaultValue={taskEdit.activityTypeId} />
                            </div>
                            {showDoneDate &&
                                <>
                                    <div className="col-2 ps-2">
                                        <label className="form-label mb-0">{translate.Date}</label>
                                    </div>
                                    <div className="col-4">
                                        <HasValidationEditor {...new HasValidationEditorProps(
                                            DatePickerEditor,
                                            taskFieldChange("dateDone"),
                                            <p className='text-danger'>{translate.RequiredField}</p>,
                                            (newVal: string | undefined) => { return !!newVal || !(taskEdit.statusId !== undefined && parseInt(taskEdit.statusId) === TaskStatus.Fulfilled); },
                                            taskEdit.dateDone,
                                            validationFunctionArray
                                        )} />
                                    </div>
                                </>}
                        </>}
                </div>
                <div className="row align-items-center mb-2 g-0">
                    <div className="col-12">
                        {fields.map(x =>
                            <>
                                <TextAreaBigEditor id='commentsBox' onChange={x.onChange} extraStyle={x.extraStyle} placeholder={x.placeholder} />
                                <ValidationMessage onChange={x.onChange} validationMethod={(value: string | undefined) => value !== undefined && value.length > 0} />
                            </>
                        )}
                    </div>
                </div>
                <ActionButtons
                    onFilesChange={taskFileChange}
                    canEdit={true}
                    taskFieldChange={taskFieldChange("tagsCsv")}
                />
                <div className="row align-items-center mb-2 g-0">
                    <div className="col-2  pe-2">
                        <label className="form-label mb-0">{translate.ActivityType2}</label>
                    </div>
                    <div className="col-10">
                        <Dropdown optionLabel={translate.SelectIt} onChange={requiredManger.makeRequiredIf(CompanyService.getSetting("mandatorytasktype"),
                            taskFieldChange("activityTypeId"), "activitytype")} items={getActivityTypeOptions} defaultValue={taskEdit.activityTypeId} />
                        <ValidationMessage onChange={CompanyService.getSetting("mandatorytasktype") ? requiredManger.getValidation("activitytype") : () => { }} defaultValue={taskEdit.activityTypeId} />
                    </div>
                </div>
                {CompanyService.getGroupName()?.length && <div className="row align-items-center mb-2 g-0">
                    <div className="col-2  pe-2">
                        <label className="form-label mb-0">{CompanyService.getGroupName()}</label>
                    </div>
                    <div className="col-10">
                        <AutocompleteSelect loadOptions={value => GroupService.getGroupSelectOptions(taskEdit.clientId, value)} onChange={x => taskFieldChange("groupId")(x?.value)} />
                    </div>
                </div>}
                <div className="row align-items-center mb-2 g-0">
                    <div className="col-2">
                        <label className="form-label mb-0">{translate.Validator}</label>
                    </div>
                    <div className="col-10">
                        <Dropdown optionLabel={translate.NotAssigned} onChange={taskFieldChange("userValidatorId")} items={getUserOptions}></Dropdown>
                    </div>
                </div>
                <div className="row align-items-center mb-2 g-0">
                    <div className="col-5 pt-1">
                        <SwitchButton
                            label={TranslationService.translate.SendEmailReminder}
                            onChange={(e: any) => {
                                taskFieldChange("reminderDay")(taskEdit.dueDate);
                                taskFieldChange("reminderHour")("10");
                                taskFieldChange("emailReminder")(e)
                            }}
                        />
                    </div>
                    {taskEdit.emailReminder &&
                        <>
                            <div className="col-1">
                                <label className="form-label mb-0">{translate.Date}</label>
                            </div>
                            <div className="col-3 pe-2 ">
                                <HasValidationEditor {...new HasValidationEditorProps(
                                    DatePickerEditor,
                                    taskFieldChange("reminderDay"),
                                    <p className='text-danger'>{translate.RequiredField}</p>,
                                    undefined,
                                    taskEdit.reminderDay,
                                    validationFunctionArray
                                )} />
                            </div>

                            <div className="col-1 ps-2">
                                <label className="form-label mb-0">{translate.Hour}</label>
                            </div>
                            <div className="col-2">
                                <Dropdown onChange={taskFieldChange("reminderHour")} items={getReminderHourOptions} defaultValue={taskEdit.reminderHour}></Dropdown>
                            </div>
                        </>}
                </div>

                {taskEdit.userResponsibleId && taskEdit.userResponsibleId !== loggedUserId &&
                    <>
                        <div className="row align-items-center mb-2 g-0">
                            <div className="col-auto">
                                <SwitchButton
                                    label={TranslationService.translate.TaskEditNotifyResponsibleLabel}
                                    onChange={e => taskFieldChange("notifyResponsible")(e)}
                                />
                            </div>
                        </div>
                        <div className="row align-items-center mb-2 g-0">
                            <div className="col-auto">
                                <SwitchButton
                                    label={TranslationService.translate.TaskEditNotifyCompletedLabel}
                                    onChange={e => taskFieldChange("notifyCompleted")(e)}
                                />
                            </div>
                        </div>
                    </>}

            </div>
            <FloatingPanelFooter>
                <button className='btn btn-primary me-3' onClick={saveTask}>
                    {translate.Save}
                    {isSaving && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>
            </FloatingPanelFooter>
        </>
    );
};

const ActionButtons = ({
    onFilesChange,
    taskFieldChange
}: any) => {
    return (
        <>
            <div className=" pt-2">
                <MultiFileUpload onFilesChange={onFilesChange} canEdit downloadFile={FileService.download} />
                <hr />
            </div>
            <div className="d-flex align-items-center mb-1">
                <div style={{ width: 45 }}>
                    <ButtonTooltipIcon icon="fa fa-tags" isLink={false} title={TranslationService.translate.Tags} onClick={() => { }} />
                </div>
                <div className=" col">
                    <MultiselectEditor
                        placeholder={TranslationService.translate.SelectIt}
                        items={
                            CompanyService.getTags().map(x => ({ value: x.Id, text: x.Value }))
                                .map(x => ({ value: x.value.toString(), label: x.text }))}
                        callback={x => taskFieldChange(x ? x.join(", ") : "")}
                    />
                </div>
            </div>
        </>
    );
};


export default NewTaskForm;