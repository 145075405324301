import { useState, useContext, useRef, useEffect } from "react";
import { TranslationService } from "../../../services/TranslationService";
import { getStatusListPlural } from "../entities/TaskStatus";
import TableContext from "../TableContext";
import NewTaskForm from "./NewTaskForm";
import TaskService from "../../../services/TaskSerivce";
import { TaskTableContextValues } from "../TaskTableProvider";
import Dropdown from "../../shared/components/Dropdown";
import CompanyService from "../../../services/CompanyService";
import FloatingPanelService from "../../shared/FloatingPanel";
import { NavigationHistoryContext } from "../../shared/NavigationHistory";
import { useHistory } from "react-router-dom";
import { TaskListRequest } from "../entities/TaskListRequest";
import { ButtonTooltipIcon } from "../../shared/components/ButtonTooltipIcon";
import { AdvancedFiltersButton } from "../../shared/components/AdvancedFilters";
import SearchInput from "../../shared/components/SearchInput";

const ListFilters = ({ userId = null, statusId = null }: { userId: string | null, statusId: number | null }) => {
    const { translate } = TranslationService;
    const tableContext = useContext(TableContext);
    const { reload, setRequest, request, applySearch } = tableContext as TaskTableContextValues;
    const getUserOptions = CompanyService.getUsers()?.map(x => ({ value: x.Id, text: x.Value ?? "", key: x.Id })) ?? [];
    const getStatusOptions = getStatusListPlural().map(x => ({ value: x.status, text: TranslationService.getTranslation(x.translateKey) }));
    const favoriteCollapsable = useRef<HTMLDivElement>(null);

    const [favoriteChevronClass, setFavoriteChevronClass] = useState("fa-chevron-down");
    const history = useHistory<{ prevRequest?: TaskListRequest }>();
    const { setCurrentPath } = useContext(NavigationHistoryContext);
    setCurrentPath({
        name: TranslationService.translate.Tasks + ` (${TranslationService.translate.Filtered})`,
        link: () => {
            history.push(`/${TranslationService.currentLanguage}/task`, { prevRequest: request });
        },
    });

    const getFavouriteOptions = () => {
        const favCount = 4;
        const favOptions = [];
        for (let i = 0; i < favCount; i++) {
            favOptions.push({ key: i, value: <div className={`favstar${i}`}></div> });
        }
        return favOptions;
    };
    const favouriteOptions = getFavouriteOptions();

    const fixedFiltersChange = (propName: keyof typeof request) => (newValue: string | number | undefined) => {
        const newRequest = { ...request, page: 0, [propName]: (newValue === "" ? undefined : newValue) };
        setRequest(newRequest);
    };

    const exportTasks = async () => {
        const { request, response } = tableContext as TaskTableContextValues;
        TaskService.export(request, response!.itemCount!);
    };

    const showNewTaskPanel = () => {
        FloatingPanelService.showPanel({
            children: <NewTaskForm onNewTask={reload} />,
            title: translate.NewTask,
            width: 629,
            height: 650,
        });
    };

    useEffect(() => {
        const onCollapse = () => {
            setFavoriteChevronClass("fa-chevron-down");
        };
        const onShow = () => {
            setFavoriteChevronClass("fa-chevron-up");
        };
        const col = favoriteCollapsable.current;
        col?.addEventListener("hidden.bs.dropdown", onCollapse);
        col?.addEventListener("shown.bs.dropdown", onShow);
        return () => {
            col?.removeEventListener("hidden.bs.dropdown", onCollapse);
            col?.removeEventListener("shown.bs.dropdown", onShow);
        };
    }, []);

    return (
        <div className="genericHeader">
            <div className="col-auto ps-0">
                <div className="actionButtonsHeader ">
                    {CompanyService.canDo("newtask") &&
                        <ButtonTooltipIcon
                            title={translate.NewTask}
                            icon="fa-light fa-plus"
                            onClick={showNewTaskPanel}
                            isLink={false}
                        />
                    }
                    {CompanyService.canDo("export") &&
                        <ButtonTooltipIcon
                            title={translate.ExportTasks}
                            icon="fa-light fa-arrow-down-to-line"
                            onClick={exportTasks}
                            isLink={false}
                        />
                    }
                    <div>
                        <AdvancedFiltersButton />
                    </div>
                </div>
            </div>
            <div className="col d-flex justify-content-between align-items-center h-100">
                <div className="searcherFilterHeader">
                    <div className="d-flex align-items-center">
                        <p className="text-granite-gray">{translate.Highlighted}:</p>
                        <div className="dropdown dropdown-intiza" ref={favoriteCollapsable}>
                            <button className="btn dropdown-toggle p-0" data-bs-toggle="dropdown" type="button">
                                <div className="select-intiza quick-filter">
                                    {(favouriteOptions.find(x => x.key === request.mainfav)?.value ?? translate.FilterDefaultOptionLabel)}
                                    <i className={"fas ms-2 " + favoriteChevronClass}></i>
                                </div>
                            </button>
                            <ul className="dropdown-menu">
                                <li className="dropdown-item" onClick={() => fixedFiltersChange("mainfav")(undefined)}>
                                    {translate.FilterDefaultOptionLabel}
                                </li>
                                {favouriteOptions.map((item: { key: number, value: JSX.Element }) =>
                                    <li className="dropdown-item" key={item.key} onClick={() => fixedFiltersChange("mainfav")(item.key)}>
                                        {item.value}
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <Dropdown quickFilter optionLabel={translate.AllStatuses} defaultValue={statusId ?? request.status} onChange={fixedFiltersChange("status")} items={getStatusOptions} />
                    </div>
                    <div className="d-flex align-items-center big-dropdown">
                        <Dropdown quickFilter optionLabel={translate.AllResponsibles} defaultValue={userId ?? request.uid} onChange={fixedFiltersChange("uid")} items={getUserOptions} />
                    </div>
                    {CompanyService.getSetting("taskvalidator") && <div className="d-flex align-items-center">
                        <p className="text-granite-gray">{translate.Validator}:</p>
                        <Dropdown quickFilter optionLabel={translate.AllValidators} defaultValue={request.vid} onChange={fixedFiltersChange("vid")} items={getUserOptions} />
                    </div>}
                </div>
                <SearchInput onSearch={applySearch} autosearch={false}  />
            </div>
        </div>
    );
};

export default ListFilters;