
import Dropdown from "../../../shared/components/Dropdown";
import { CheckBoxEditor, TextEditor } from "../../../shared/components/Editors";
import { TranslationService, TranslationsKey } from "../../../../services/TranslationService";
import TooltipComponent from "../../../shared/TooltipComponent";
import Collapsable from "../../../shared/bootstrap/Collapsable";
import { FloatingPanelFooter } from "../../../shared/components/FloatingPanelFooter";
import Loading from "../../../shared/components/Loading";
import ModalService from "../../../shared/bootstrap/Modal";
import FloatingPanelService from "../../../shared/FloatingPanel";
import { getError, handleErrorWithToast, isAbortError } from "../../../../utils/RequestUtils";
import { useEndpointData } from "../../../../utils/TableUtils";
import { v4 } from "uuid";
import { Cast } from "../../../../utils/Utils";
import { negate } from "../../../../utils/FuncUtils";
import { useState, useCallback, useEffect, useRef } from "react";
import CompanyService, { DataType, Entities } from "../../../../services/CompanyService";
import FieldConfigurationService, { AdditionalDefinitionsListResponse, AdditionalDefinitionsGetResponse } from "../../../../services/FieldConfigurationService";
import { ToastService } from "../../../shared/bootstrap/Toast";
import { TupleToUnion } from "../../../../utils/TypeUtils";

const AdditionalDefinitionsEdit = ({ reload, fieldId = undefined, entityId = undefined, notHideFloatingPanel = false }: { reload: () => void, fieldId?: number, entityId?: number, notHideFloatingPanel?: boolean }) => {
    const [field, setField] = useState<AdditionalDefinitionsListResponse.Item>(() => getDefaultField(entityId ?? 0));
    const [showMenu, setShowMenu] = useState(false);
    const dataTypeOptions = [
        { value: DataType.Text, text: TranslationService.translate.Freetext },
        { value: DataType.List, text: TranslationService.translate.List },
        { value: DataType.Date, text: TranslationService.translate.Date },
        { value: DataType.Number, text: TranslationService.translate.Number },
        { value: DataType.Currency, text: TranslationService.translate.Currency },
        { value: DataType.Phone, text: TranslationService.translate.Phone },
        { value: DataType.Link, text: TranslationService.translate.Link },
    ];
    const endpoint = useCallback(() => fieldId ? FieldConfigurationService.getAdditionalDefinition(fieldId) : Promise.resolve({} as AdditionalDefinitionsGetResponse | Error), [fieldId]);
    const { loading, response } = useEndpointData(endpoint);
    useEffect(() => (fieldId && response && setField(response.item)) || undefined, [fieldId, response]);
    if (loading) {
        return (<Loading />);
    }

    const updateField = <TKey extends keyof AdditionalDefinitionsListResponse.Item,>(propName: TKey) => (value: AdditionalDefinitionsListResponse.Item[TKey]) => {
        setField(field => ({ ...field, [propName]: value }) as AdditionalDefinitionsListResponse.Item);
    };
    const columnWidth = [
        { value: undefined, text: TranslationService.translate.Regular },
        { value: 160, text: TranslationService.translate.Big },
        { value: 240, text: TranslationService.translate.Bigger },
    ];

    const onDelete = () => {
        ModalService.showDefaultModal({
            acceptButtonClassName: "btn btn-danger",
            acceptButtonLabel: TranslationService.translate.Delete,
            message: TranslationService.translate.ConfirmAdditionalFieldDelete,
            title: TranslationService.translate.AttentionExclamation,
            onAcceptClick: async () => {
                FloatingPanelService.hidePanel();
                await handleErrorWithToast(FieldConfigurationService.deleteAdditionalDefinition(field!.AdditionalDefinitionID));
                reload();
            },
        });
    };

    const onSave = async () => {
        const result = await FieldConfigurationService.setAdditionalDefinition(field);
        if (isAbortError(result)) {
            return;
        }
        if (result instanceof Error) {
            const error = getError(result);
            if (error.message === "Fail-PersonKey") {
                ModalService.showDefaultModal({
                    message: TranslationService.translate.CantSelectThisFieldAsIdentifierBecauseThereAreCustomersWithTheValueOfThisFieldRepeated,
                });
            } else if (error.message === "Fail-Name") {
                ToastService.showToast(TranslationService.translate.FieldAlreadyCreated, undefined, "danger");
            } else {
                ToastService.showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
            }
            return;
        }
        !notHideFloatingPanel && FloatingPanelService.hidePanel();
        ToastService.showToast(TranslationService.translate.SavedChanges, undefined, "success");
        reload();
    };

    const updateAdditional = <TKey extends keyof AdditionalDefinitionsListResponse.AdditionalDefinitionItem,>(propName: TKey, obj: AdditionalDefinitionsListResponse.AdditionalDefinitionItem) => (value: AdditionalDefinitionsListResponse.AdditionalDefinitionItem[TKey]) => {
        setField(field => {
            obj[propName] = value;
            return ({ ...field });
        });
    };
    // es-AR=IVA Es;en-US=VAT;pt-BR=IVA BR
    const availableLanguages = ["es-AR", "en-US", "pt-BR"] as const;
    type Language = TupleToUnion<typeof availableLanguages>;
    const extractLanguagesNames = (field: AdditionalDefinitionsListResponse.Item) => {
        const langStr = field.LanguageNames ?? "";
        const langMap = Object.fromEntries(availableLanguages.map(x => [x, ""]));
        langStr.split(";").forEach(x => {
            const split = x.split("=");
            langMap[split[0]] = split[1] ?? "";
        });
        return langMap as Record<Language, string>;
    };
    const setLanguageName = (key: Language) => (val: string) => {
        setField(field => {
            const langMap = extractLanguagesNames(field);
            langMap[key] = val;
            return { ...field, LanguageNames: Object.keys(langMap).map(x => x + "=" + langMap[x as Language]).join(";") };
        });
    };

    const TextField = ({ fieldKey, langKey }: { fieldKey: keyof AdditionalDefinitionsListResponse.Item, langKey: TranslationsKey }) =>
        <div className="form-group mb-3">
            <label>{TranslationService.translate[langKey]}</label>
            <TextEditor onChange={updateField(fieldKey)} value={field?.[fieldKey]}></TextEditor>
        </div>;

    return (
        <>
            <div className="floatingBody p-4">
                <div className="form-group mb-3">
                    <label>{TranslationService.translate.Name}</label>
                    <TextEditor onChange={updateField("Name")} value={field?.Name}></TextEditor>
                </div>
                {field.Entity === Entities.Invoice && availableLanguages.map(x => <div className="form-group mb-3" key={x}>
                    <label>{TranslationService.translate.Name} ({x})</label>
                    <TextEditor onChange={setLanguageName(x)} value={extractLanguagesNames(field)[x]}></TextEditor>
                </div>)}
                {field?.Type === DataType.Link && <div className="form-group mb-3">
                    <label>{TranslationService.translate.LinkText}</label>
                    <TextEditor onChange={updateField("LinkText")} defaultValue={field!.PublicName} ></TextEditor>
                </div>}
                <div className="form-group mb-3">
                    <label>{TranslationService.translate.Type}</label>
                    <Dropdown onChange={(x) => { updateField("Type")(x); fieldId && ModalService.showDefaultModal({ title: TranslationService.translate.Warning, message: TranslationService.translate.IfYouChangeTheFieldTypeAllValuesContainedInTheFieldWillBeErased }); }} items={dataTypeOptions} value={field?.Type}></Dropdown>
                </div>
                {field?.Type === DataType.List &&
                    <section>
                        <hr className="my-4"></hr>
                        <h5 className="mb-4">{TranslationService.translate.ListingOptions}</h5>
                        <CheckBoxEditor onChange={updateField("AutoPopulate")} defaultValue={field!.AutoPopulate} message={TranslationService.translate.AutoCompleteOnImport} />
                        <table className="table optionsList w-100 mt-2">
                            <thead>
                                <tr>
                                    <th className="w-50">{TranslationService.translate.Option}</th>
                                    <th className="w-25 text-center">{TranslationService.translate.Default2}</th>
                                    <th className="w-25"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {field.AdditionalDefinitionItems.map(x =>
                                    <tr key={x.AdditionalDefinitionItemID ?? Cast<{ guid: string }>(x).guid}>
                                        <td>{<TextEditor onChange={updateAdditional("Value", x)} defaultValue={x.Value} />}</td>
                                        <td>
                                            <div className="position-relative d-flex justify-content-center">
                                                <CheckBoxEditor onChange={updateAdditional("Default", x)} defaultValue={x.Default} message="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex flex-row-nowrap">
                                                <button className="edit w-100" onClick={() => updateField("AdditionalDefinitionItems")(field.AdditionalDefinitionItems.filter(y => y !== x))}><i className="far fa-trash text-danger"></i></button>
                                            </div>
                                        </td>
                                    </tr>)}
                                <tr>
                                    <td></td><td></td>
                                    <td>
                                        <div className="d-flex justify-content-center">
                                            <button className="btn btn-link" onClick={() => updateField("AdditionalDefinitionItems")([...field.AdditionalDefinitionItems, Cast<AdditionalDefinitionsListResponse.AdditionalDefinitionItem>({ guid: v4() })])}>+ {TranslationService.translate.Add}</button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                }
                <div>
                    <button className="btn btn-link px-0" onClick={() => setShowMenu(!showMenu)}>{showMenu === false ? `+ ${TranslationService.translate.ShowAdvancedOptions}` : `- ${TranslationService.translate.HideAdvancedOptions}`}</button>
                    <hr className="my-4"></hr>
                    <Collapsable show={showMenu} >
                        <div className="mt-2">
                            {(field?.Type === DataType.Text || field?.Type === DataType.List) &&
                                <div className="d-flex gap-3 align-items-center mb-3">
                                    <div className="form-check ps-0">
                                        <label className="form-check-label mb-0">{TranslationService.translate.ColumnWidth}</label>
                                    </div>
                                    <Dropdown onChange={updateField("Width")} defaultValue={field!.Width} items={columnWidth}></Dropdown>
                                </div>}
                            {/* CHECKS CLIENT FIELDS */}
                            {field.Entity === Entities.Client && <>
                                <FieldPrimaryKey isPK={field!.IsPK} setPK={updateField("IsPK")} />
                                <div className="d-flex">
                                    <CheckBoxEditor onChange={updateField("IncludeInList")} defaultValue={field!.IncludeInList} message={TranslationService.translate.IncludeInReports} />
                                    <TooltipComponent title={TranslationService.translate.IncludeInvoicesPaymentsActivitiesTags}>
                                        <i className="ms-2 mt-1 fal fa-question-circle"></i></TooltipComponent>
                                </div>
                                <CheckBoxEditor onChange={updateField("Hidden")} defaultValue={field!.Hidden} message={TranslationService.translate.HiddenInDetails} />
                                {field?.Type === DataType.Phone && <CheckBoxEditor onChange={updateField("Whatsapp")} defaultValue={field!.Whatsapp} message={TranslationService.translate.TypeWhatsApp} />}
                                <CheckBoxEditor onChange={updateField("Right")} defaultValue={field!.Right} message={TranslationService.translate.ShowRight} />
                                <CheckBoxEditor onChange={updateField("ManualEdit")} defaultValue={field!.ManualEdit} message={TranslationService.translate.AllowManualEdit} />
                                <CheckBoxEditor onChange={negate(updateField("ExcludeInFilters"))} defaultValue={!field!.ExcludeInFilters} message={TranslationService.translate.IncludeInFilters} />
                                <CheckBoxEditor onChange={updateField("IncludeInCustomerPortal")} defaultValue={field!.IncludeInCustomerPortal} message={TranslationService.translate.IncludeInCustomerPortal} />
                            </>}
                            {field.Entity === Entities.Invoice && <>
                                <TextField fieldKey="PublicName" langKey="PublicName" />
                                <div className="d-flex">
                                    <CheckBoxEditor onChange={updateField("IncludeInList")} defaultValue={field.IncludeInList} message={TranslationService.translate.ShowThisFieldInTheInvoiceList} />
                                    <TooltipComponent title={TranslationService.translate.IfYouCheckThisOptionThisFieldWillBeShownInTheInvoiceListOfACustomersPage} >
                                        <i className="ms-2 mt-1 fal fa-question-circle"></i></TooltipComponent>
                                </div>
                                <div className="d-flex">
                                    <CheckBoxEditor onChange={updateField("ShowToClient")} defaultValue={field!.ShowToClient} message={TranslationService.translate.ShowThisFieldInEmailsToCustomers} />
                                    <TooltipComponent title={TranslationService.translate.ShowThisFieldInEmailsToCustomersTooltip} >
                                        <i className="ms-2 mt-1 fal fa-question-circle"></i></TooltipComponent>
                                </div>
                                {[DataType.Currency, DataType.Number].includes(field.Type) && <div className="d-flex">
                                    <CheckBoxEditor onChange={updateField("Summarize")} defaultValue={field!.Summarize} message={TranslationService.translate.Totalize} />
                                    <TooltipComponent title={TranslationService.translate.DisplaysTheTotalSumOfThisFieldInTheInvoiceList} >
                                        <i className="ms-2 mt-1 fal fa-question-circle"></i></TooltipComponent>
                                </div>}
                                <CheckBoxEditor onChange={updateField("ManualEdit")} message={TranslationService.translate.AllowManualEdit} />
                                <CheckBoxEditor onChange={negate(updateField("ExcludeInFilters"))} defaultValue={!field.ExcludeInFilters} message={TranslationService.translate.IncludeInFilters} />
                            </>}
                            {field.Entity === Entities.Payment && <>
                                <CheckBoxEditor onChange={updateField("IncludeInList")} defaultValue={field!.IncludeInList} message={TranslationService.translate.IncludeInReports} />
                                <CheckBoxEditor onChange={updateField("ManualEdit")} defaultValue={field!.ManualEdit} message={TranslationService.translate.AllowManualEdit} />
                                <CheckBoxEditor onChange={negate(updateField("ExcludeInFilters"))} defaultValue={!field!.ExcludeInFilters} message={TranslationService.translate.IncludeInFilters} />
                            </>}
                        </div>
                    </Collapsable>

                    {/* <p className="my-3">
                <b>¡ATENCIÓN! </b>Este campo está asociado a un proceso automático de actualización de facturas. Por esta razón no es posible eliminarlo.
            </p> */}

                    {/* <CheckBoxEditor onChange={updateField("IncludeInClientExport")} defaultValue={field!.IncludeInClientExport} message={"TRANSLATE - incluir en reporte"} value={field?.IncludeInClientExport}/>
        <CheckBoxEditor onChange={updateField("Hidden")} defaultValue={field!.Hidden} message={"Hidden"} value={field?.Hidden}/>
        <CheckBoxEditor onChange={updateField("Right")} defaultValue={field!.Right} message={"Right"} value={field?.Right}/>
        <CheckBoxEditor onChange={updateField("ManualEdit")} defaultValue={field!.ManualEdit} message={"ManualEdit"} value={field?.ManualEdit}/>
        <CheckBoxEditor onChange={updateField("ExcludeInFilters")} defaultValue={field!.ExcludeInFilters} message={"!!ExcludeInFilters"} value={!!field?.ExcludeInFilters}/>
        <CheckBoxEditor onChange={updateField("IncludeInCustomerPortal")} defaultValue={field!.IncludeInCustomerPortal} message={"IncludeInCustomerPortal"} value={field?.IncludeInCustomerPortal}/>
        */}
                </div >
            </div >
            <FloatingPanelFooter>
                {field?.AdditionalDefinitionID !== undefined && <button className="btn btn-danger" onClick={onDelete}>{TranslationService.translate.Delete}</button>}
                <button className="btn btn-primary me-3" onClick={onSave}>{TranslationService.translate.Save}</button>
            </FloatingPanelFooter>
        </>

    );
};
const getDefaultField = (entityId: number) => ({
    "IsPK": false,
    "typeName": "txt",
    "type": 0,
    "TypeName": "Free text",
    "entity": entityId,
    "AdditionalDefinitionID": undefined!,
    "CreatorUserID": parseFloat(CompanyService.getUserid()),
    "Created": undefined!,
    "Type": 0,
    "Name": "",
    "LinkText": "",
    "Field": 3,
    "Entity": entityId,
    "IncludeInList": false,
    "ShowToClient": false,
    "ShowAlways": false,
    "IncludeInClientExport": false,
    "Width": undefined,
    "ManualEdit": true,
    "HasIODependency": false,
    "ExcludeInFilters": false,
    "PublicName": undefined,
    "LanguageNames": "es-AR=;en-US=;pt-BR=",
    "AutoPopulate": false,
    "AdditionalDefinitionID_ref": null,
    "Hidden": false,
    "Whatsapp": false,
    "Summarize": false,
    "Right": false,
    "IncludeInCustomerPortal": false,
    "IOStatusDependencies": [],
    "AdditionalDefinitionItems": []
}) as AdditionalDefinitionsListResponse.Item;

export const FieldPrimaryKey = ({ setPK, isPK }: { isPK: boolean, setPK: (val: boolean) => void }) => {
    const defaultPK = useRef(isPK);
    return (<>
        <CheckBoxEditor onChange={setPK} defaultValue={isPK} message={<><span className="text-danger fw-bold">{isPK ? TranslationService.translate.PKFieldTooltipComponent : TranslationService.translate.ThisFieldIdentifiesTheCustomer}</span> {defaultPK.current && <TooltipComponent title={TranslationService.translate.IncludeInvoicesPaymentsActivitiesTags}>
            <i className="ms-1 fal fa-question-circle text-primary" />
        </TooltipComponent>}</>} disabled={defaultPK.current} />
    </>);
};

export default AdditionalDefinitionsEdit;
