import { ImportExportGet } from "../entities/import/ImportExportGet";
import ImportTemplateEditActivity from "../pages/company/Configuration/ImportTemplate/ImportTemplateEditActivity";
import { TemplateField, additionalDefsToFieldData, createFieldData } from "../pages/company/Configuration/ImportTemplate/ImportTemplateEditBase";
import ImportTemplateEditClient from "../pages/company/Configuration/ImportTemplate/ImportTemplateEditClient";
import ImportTemplateEditClientAutomatic from "../pages/company/Configuration/ImportTemplate/ImportTemplateEditClientAutomatic";
import ImportTemplateEditCurrencies from "../pages/company/Configuration/ImportTemplate/ImportTemplateEditCurrencies";
import ImportTemplateEditDictionary from "../pages/company/Configuration/ImportTemplate/ImportTemplateEditDictionary";
import ImportTemplateEditFile from "../pages/company/Configuration/ImportTemplate/ImportTemplateEditFile";
import ImportTemplateEditInvoice from "../pages/company/Configuration/ImportTemplate/ImportTemplateEditInvoice";
import ImportTemplateEditInvoiceAutomatic from "../pages/company/Configuration/ImportTemplate/ImportTemplateEditInvoiceAutomatic";
import ImportTemplateEditInvoiceDelete from "../pages/company/Configuration/ImportTemplate/ImportTemplateEditInvoiceDelete";
import ImportTemplateEditInvoiceNew from "../pages/company/Configuration/ImportTemplate/ImportTemplateEditInvoiceNew";
import ImportTemplateEditInvoiceUpdate from "../pages/company/Configuration/ImportTemplate/ImportTemplateEditInvoiceUpdate";
import ImportTemplateEditTransaction from "../pages/company/Configuration/ImportTemplate/ImportTemplateEditTransaction";
import FloatingPanelService from "../pages/shared/FloatingPanel";
import { ImportTemplateListResponse } from "../pages/shared/entities/ImportTemplateListResponse";
import CompanyService, { Entities } from "./CompanyService";
import InvoiceService from "./InvoiceService";
import RequestService from "./RequestService";
import { TranslationService } from "./TranslationService";

class ImportTemplateService {
    public static getList(): Promise<ImportTemplateListResponse | Error> {
        return RequestService.post("/importtemplate/list", {});
    }

    public static set(data: ImportTemplateListResponse.Item): Promise<ImportTemplateListResponse | Error> {
        return RequestService.post("/importtemplate/set", { item: data });
    }

    public static runIOUpdate(): Promise<ImportTemplateListResponse | Error> {
        return RequestService.post("/importtemplate/RunIOUpdate", {});
    }

    public static runPersonUpdate(): Promise<ImportTemplateListResponse | Error> {
        return RequestService.post("/importtemplate/RunPersonUpdate", {});
    }

    public static showImportTemplateEditModal(template?: ImportTemplateListResponse.Item, importsList: ImportTemplateListResponse | undefined = undefined, onSubmit: () => void = () => undefined, templateEntityId: undefined | number = undefined) {
        const Editors = [
            ImportTemplateEditInvoice,
            ImportTemplateEditClient,
            ImportTemplateEditTransaction,
            ImportTemplateEditActivity,
            ImportTemplateEditFile,
            ImportTemplateEditInvoiceUpdate,
            ImportTemplateEditInvoiceAutomatic,
            ImportTemplateEditInvoiceDelete,
            ImportTemplateEditInvoiceNew,
            ImportTemplateEditClientAutomatic,
            ImportTemplateEditCurrencies,
            ImportTemplateEditDictionary
        ];
        const Edit = Editors[template?.entity ?? templateEntityId ?? -1];
        if (Edit === undefined) {
            alert("not implemented esese");
            return;
        }
        FloatingPanelService.showPanel({
            children: <Edit data={template} reload={onSubmit} importsList={importsList} entityId={template?.Entity ?? templateEntityId}  />,
            title: TranslationService.translate.EditImportTemplate,
            width: 800,
            height: 600,
            position: "center",
        });
    }

    public static getExportTemplate(importtemplateid: number): Promise<ImportExportGet | Error> {
        return RequestService.post("/exporttemplate/get", { importtemplateid });
    }

    public static setExportTemplate(importtemplateid: number, list: {
        Field?: number | string,
        Position: number,
        Entity: number,
    }[]): Promise<unknown | Error> {
        return RequestService.post("/exporttemplate/set", { importtemplateid, list });
    }

    public static getTemplateFields(entityId: number) {
        switch (entityId) {
            case 0: /* Invoice */
            case 5: /* Invoice Update */
            case 6: /* Invoice Automatic */
            case 8: /* Invoice New */
                const invoiceFields: TemplateField[] = InvoiceService.getTableHeaders(false).map(x => (
                    {
                        fieldId: x.fieldId,
                        key: x.key,
                        label: x.label,
                    }));

                let createdDate = invoiceFields.find(x => x.fieldId === "emittedDate");
                if (createdDate) {
                    createdDate.fieldId = "createdDate";
                    createdDate.key = "createdDate";
                }

                const amountField = invoiceFields.find(x => x.fieldId === "amount")!;
                amountField.label = () => <>{TranslationService.translate.AmountButPendingSometimes}</>;

                const descriptionField = invoiceFields.find(x => x.fieldId === "description");
                if (descriptionField) {
                    descriptionField.key = "description";
                }
                const pendingIndex = invoiceFields.findIndex(x => x.key === "pending");
                if (pendingIndex >= 0) {
                    invoiceFields.splice(pendingIndex, 1);
                }

                invoiceFields.unshift(
                    {
                        fieldId: "contact",
                        key: "contact",
                        label: () => <>CUIT</>,
                    },
                    {
                        fieldId: "currency",
                        key: "currency",
                        label: () => <>{TranslationService.translate.Currency}</>,
                    });

                if (CompanyService.getGroupName()) {
                    invoiceFields.unshift({
                        fieldId: "group",
                        key: "group",
                        label: () => <>{CompanyService.getGroupName()}</>,
                    })
                }
                return invoiceFields;

            case 1: /* Client */
            case 9: /* Client Automatic */
                const clientFields: TemplateField[] = additionalDefsToFieldData(CompanyService.getAdditionalDefinitions().filter(x => x.Entity === Entities.Client));
                clientFields.unshift(
                    createFieldData('name', TranslationService.translate.Name),
                    createFieldData('contact', TranslationService.translate.Contact),
                    createFieldData('email', TranslationService.translate.Email),
                    createFieldData('phone', TranslationService.translate.Phone),
                    createFieldData('address', TranslationService.translate.Address),
                    createFieldData('comments', TranslationService.translate.Notes),
                    createFieldData('creditlimit', TranslationService.translate.CreditLimit),
                );
                const cuit = clientFields.find(x => x.fieldId === "7");
                if (cuit) {
                    cuit.label = () => <>CUIT</>;
                }
                return clientFields;
            case 2: /* Transaction */
                const transactionFields: TemplateField[] = additionalDefsToFieldData(CompanyService.getAdditionalDefinitions().filter(x => x.Entity === Entities.Payment));
                transactionFields.unshift(
                    createFieldData("client", "CUIT"),
                    createFieldData("referenceNumber", InvoiceService.getReferenceNumberName()),
                    createFieldData("date", TranslationService.translate.Date),
                    createFieldData("amount", TranslationService.translate.Amount),
                    createFieldData("comments", TranslationService.translate.Observations),
                    createFieldData("transactiontype", TranslationService.translate.Type),
                );
                return transactionFields;
            case 3: /* Activity */
                return [
                    createFieldData("date", TranslationService.translate.Date),
                    createFieldData("user", TranslationService.translate.User),
                    createFieldData("client", TranslationService.translate.Client),
                    createFieldData("group", CompanyService.getGroupName()),
                    createFieldData("content", TranslationService.translate.Content),
                    createFieldData("tags", TranslationService.translate.Tags),
                    createFieldData("activitytype", TranslationService.translate.ActivityType),
                ];
            case 7:
                const requiredIds = ["22962", "referenceNumber"];
                const invoiceDeleteFields: TemplateField[] = InvoiceService.getTableHeaders(false)
                    .filter(x => requiredIds.includes(x.fieldId))
                    .map(x => (
                        {
                            fieldId: x.fieldId,
                            key: x.key,
                            label: x.label,
                        }));
                invoiceDeleteFields.unshift({
                    fieldId: "contact",
                    key: "contact",
                    label: () => <>CUIT</>,
                });
                return invoiceDeleteFields;
            case 11: /* Dictionary */
                return [{
                    fieldId: "term",
                    key: "term",
                    label: () => <>{TranslationService.translate.Term}</>,
                }, {
                    fieldId: "value",
                    key: "value",
                    label: () => <>{TranslationService.translate.NewValue}</>,
                },];
            default:
                throw new Error("Template fields not implemented");
        }
    }
}

export default ImportTemplateService;