import intizaLogoPng from "../../assets/img/intizaLogoWhite.png";
import { Link } from "react-router-dom";
import { TranslationService } from "../../services/TranslationService";
import ModalService from "./bootstrap/Modal";
import Label from "./form/Label";
import { RequiredManager, ValidationMessage } from "./RequieredManager";
import { ContactService } from "../../services/ContactService";
import { handleErrorWithToast } from "../../utils/RequestUtils";
import { ToastService } from "./bootstrap/Toast";

const Footer = () => {
    const year = new Date().getFullYear();

    const OpenTechnicalSupportModal = () => {
        const requiredManager = new RequiredManager();
        const message = {
            name: "",
            email: "",
            company: "",
            country: "",
            phone: "",
            message: "",
        };
        const onSubmit = async () => {
            if (!requiredManager.validate()) {
                return;
            }
            await handleErrorWithToast(ContactService.sendMessage(message));
            ToastService.showToast(TranslationService.translate.OnContactMessage,
                TranslationService.translate.OnContactTitle,
                "success");
            ModalService.hideModal();
        };
        ModalService.showDefaultModal({
            title: TranslationService.translate.ContactSupport,
            hideClose: true,
            message: () => {
                const change = <T extends keyof typeof message,>(key: T) => (value: string) => message[key] = value;
                const nameChange = requiredManager.makeRequiredWithId(change("name"), "name");
                const emailChange = requiredManager.makeRequiredWithId(change("email"), "email");
                const countryChange = requiredManager.makeRequiredWithId(change("country"), "country");
                const phoneChange = requiredManager.makeRequiredWithId(change("phone"), "phone");
                const messageChange = requiredManager.makeRequiredWithId(change("message"), "message");
                return (<>
                    <div className="">
                        <Label label={TranslationService.translate.Name}>
                            <input className="form-control" onChange={(e) => nameChange(e.target.value)} />
                            <ValidationMessage onChange={nameChange} />
                        </Label>
                        <Label label={TranslationService.translate.Email}>
                            <input className="form-control" onChange={(e) => emailChange(e.target.value)} />
                            <ValidationMessage onChange={emailChange} />
                        </Label>
                        <Label label={TranslationService.translate.Company}>
                            <input className="form-control" onChange={(e) => change("company")(e.target.value)} />
                        </Label>
                        <Label label={TranslationService.translate.Country}>
                            <input className="form-control" onChange={(e) => countryChange(e.target.value)} />
                            <ValidationMessage onChange={countryChange} />
                        </Label>
                        <Label label={TranslationService.translate.Phone}>
                            <input className="form-control" onChange={(e) => phoneChange(e.target.value)} />
                            <ValidationMessage onChange={phoneChange} />
                        </Label>
                        <Label label={TranslationService.translate.Message}>
                            <textarea className="form-control" onChange={(e) => messageChange(e.target.value)} />
                            <ValidationMessage onChange={messageChange} />
                        </Label>
                    </div>
                    <div className="modal-footer px-0">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{TranslationService.translate.Close}</button>
                        <button type="button" className={"btn btn-primary"} onClick={onSubmit}>{TranslationService.translate.Send}</button>
                    </div>
                </>);
            }
        });
    };

    return (
        <footer>
            <div className="container-fluid">
                <div className="row justify-content-between align-items-center">
                    {/* Logo */}
                    <div className="col-12 col-md-3 text-center text-md-start mb-3 mb-md-0">
                        <Link to={"/"} className="navbar-brand">
                            <img src={intizaLogoPng} alt="Intiza" />
                        </Link>
                    </div>

                    {/* Links */}
                    <div className="col-12 col-md-6 text-center mb-3 mb-md-0">
                        <ul className="list-unstyled d-flex flex-column flex-md-row justify-content-center mb-0">
                            <li className="mb-2 mb-md-0 mx-3 mx-md-0">
                                <a href="/" onClick={(e) => { e.preventDefault(); OpenTechnicalSupportModal(); }}>
                                    {TranslationService.translate.ContactSupport}
                                </a>
                            </li>
                            <li className="mb-2 mb-md-0 mx-3 mx-md-0">
                                <a href="https://ayuda.intiza.com" target="_blank" rel="noreferrer">
                                    {TranslationService.translate.Help}
                                </a>
                            </li>
                            <li className="mb-2 mb-md-0 mx-3 mx-md-0">

                                <Link to={`/${TranslationService.currentLanguage}/home/contact`}>
                                    {TranslationService.translate.ContactInfo}
                                </Link>

                            </li>
                        </ul>
                    </div>

                    {/* Redes sociales */}
                    <div className="col-12 col-md-3 text-center text-md-end mb-3 mb-md-0">
                        <div className="rrss d-flex justify-content-center justify-content-md-end">
                            <a href="https://www.linkedin.com/company/intiza/" target="_blank" rel="noreferrer" className="mx-2">
                                <i className="fa-brands fa-linkedin"></i>
                            </a>
                            <a href="https://x.com/IntizaSaaS" rel="noreferrer" className="mx-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 20 20">
                                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>

                {/* Subfooter */}
                <div className="row">
                    <div className="col-12 text-center">
                        <div className="subfooter">
                            Copyright {year} | Intiza S.A. {TranslationService.translate.Rights}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;